*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    max-width: 1300px;
    padding: 0 20px;
    margin: auto;
}
